@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollable-background {
  background-size: 200% 200%; /* Slightly larger background for smoother transitions */
 
  height: min-content;
  width: 100%;
  overflow-x: hidden;
}

body {
  
  background-size: 400% 400%;
  height: 100vh;
  margin: 0;
}

.privacy-policy-container {
  max-width: 800px;
  margin: auto;
  padding: 40px;
  background-color: #ffffff; /* White background only for the Privacy Policy box */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
  border-radius: 8px;
}

.privacy-policy-container h1 {
  color: #4a4a4a;
  font-size: 2.5rem;
  text-align: center;
  margin-bottom: 1rem;
}

.privacy-policy-container h2 {
  color: #333;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  margin-bottom: 0.75rem;
}

.privacy-policy-container p,
.privacy-policy-container li {
  color: #555;
  line-height: 1.6;
}

.privacy-policy-container a {
  color: #1a73e8;
  text-decoration: underline;
}

.list-disc {
  padding-left: 1.25rem;
}




